// ** Logo
// import logo from '@src/assets/images/logo/logo.png'
import './style.css'

const SpinnerComponent = () => {
  return (
    // <div className='fallback-spinner app-loader'>
    //   <img className='fallback-logo' src={logo} alt='logo' />
    //   <div className='loading'>
    //     <div className='effect-1 effects'></div>
    //     <div className='effect-2 effects'></div>
    //     <div className='effect-3 effects'></div>
    //   </div>
    // </div>
    <div id="loading-bg">
      <div id="wifi-loader">
        <svg class="circle-outer" viewBox="0 0 86 86">
          <circle class="back" cx="43" cy="43" r="40"></circle>
          <circle class="front" cx="43" cy="43" r="40"></circle>
          <circle class="new" cx="43" cy="43" r="40"></circle>
        </svg>
        <svg class="circle-middle" viewBox="0 0 60 60">
          <circle class="back" cx="30" cy="30" r="27"></circle>
          <circle class="front" cx="30" cy="30" r="27"></circle>
        </svg>
        <svg class="circle-inner" viewBox="0 0 34 34">
          <circle class="back" cx="17" cy="17" r="14"></circle>
          <circle class="front" cx="17" cy="17" r="14"></circle>
        </svg>
        <div class="text" data-text="Loading"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
