// ** Auth Endpoints
export default {
  loginEndpoint: 'https://screening.coste.life/api/DdcPass/auth/login',
  registerEndpoint: 'https://screening.coste.life/api/DdcPass/auth/resgister',
  refreshEndpoint: 'https://screening.coste.life/api/DdcPass/auth/refresh-token',
  logoutEndpoint: 'https://screening.coste.life/api/DdcPass/auth/logout',
  // loginEndpoint: '/jwt/login',
  // registerEndpoint: '/jwt/register',
  // refreshEndpoint: '/jwt/refresh-token',
  // logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'refreshToken'
}
